<template>
    <!-- details -->
    <section class="section section--head  section--details-bg">
        <div class="container">
            <!-- article -->
            <div class="article">
                <div class="row">
                    <div class="col-12 col-xl-9" v-for="publish in content" :key="publish.id">
                        <div class="row">
                            <div class="col-12">
                                <video id="plyr" class="plyr" controls="" style="margin-right: 0px; width: 100%;"
                                    controlsList="nodownload" preload="none" :poster="publish.carsoul_img">
                                    <source :src="publish.source" type="Video/mp4" size="720">
                                </video>
                                <br>
                                <div class="article__content">
                                    <div class="article__content">
                                        <h3>{{ publish.title_ar }}</h3>
                                        <ul class="list">
                                            <!-- <template > -->
                                            <li>

                                                <input type="checkbox" id="checkbox" @click="addLike" />
                                                <label for="checkbox" aria-disabled="true">

                                                    <svg id="heart-svg" style="width:40px ;" viewBox="467 392 58 57"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <g id="Group" fill="none" fill-rule="evenodd"
                                                            transform="translate(467 392)">
                                                            <path
                                                                d="M29.144 20.773c-.063-.13-4.227-8.67-11.44-2.59C7.63 28.795 28.94 43.256 29.143 43.394c.204-.138 21.513-14.6 11.44-25.213-7.214-6.08-11.377 2.46-11.44 2.59z"
                                                                id="heart" fill="#AAB8C2" />
                                                            <circle id="main-circ" fill="#E2264D" opacity="0" cx="29.5"
                                                                cy="29.5" r="1.5" />

                                                            <g id="grp7" opacity="0" transform="translate(7 6)">
                                                                <circle id="oval1" fill="#9CD8C3" cx="2" cy="6" r="2" />
                                                                <circle id="oval2" fill="#8CE8C3" cx="5" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp6" opacity="0" transform="translate(0 28)">
                                                                <circle id="oval1" fill="#CC8EF5" cx="2" cy="7" r="2" />
                                                                <circle id="oval2" fill="#91D2FA" cx="3" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp3" opacity="0" transform="translate(52 28)">
                                                                <circle id="oval2" fill="#9CD8C3" cx="2" cy="7" r="2" />
                                                                <circle id="oval1" fill="#8CE8C3" cx="4" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp2" opacity="0" transform="translate(44 6)">
                                                                <circle id="oval2" fill="#CC8EF5" cx="5" cy="6" r="2" />
                                                                <circle id="oval1" fill="#CC8EF5" cx="2" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp5" opacity="0" transform="translate(14 50)">
                                                                <circle id="oval1" fill="#91D2FA" cx="6" cy="5" r="2" />
                                                                <circle id="oval2" fill="#91D2FA" cx="2" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp4" opacity="0" transform="translate(35 50)">
                                                                <circle id="oval1" fill="#F48EA7" cx="6" cy="5" r="2" />
                                                                <circle id="oval2" fill="#F48EA7" cx="2" cy="2" r="2" />
                                                            </g>

                                                            <g id="grp1" opacity="0" transform="translate(24)">
                                                                <circle id="oval1" fill="#9FC7FA" cx="2.5" cy="3"
                                                                    r="2" />
                                                                <circle id="oval2" fill="#9FC7FA" cx="7.5" cy="2"
                                                                    r="2" />
                                                            </g>
                                                        </g>
                                                    </svg>
                                                </label>

                                                &nbsp; {{ totalLikes }} إعجاب
                                            </li>
                                            <!-- </template>
<template v-for="publish2 in getInteractionsByID" :key="publish2.id"> -->
                                            <li>
                                                <svg fill="#0000006b" height="19px" width="19px" version="1.1"
                                                    id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                                    viewBox="0 0 325.592 325.592" xml:space="preserve">
                                                    <g>
                                                        <path
                                                            d="M81.762,248.649c9.66,0,17.52-7.859,17.52-17.52c0-2.326-0.466-4.545-1.292-6.578l36.888-37.708   c2.186,0.982,4.6,1.541,7.148,1.541c2.477,0,4.832-0.523,6.971-1.455L167,204.935c-0.694,1.883-1.092,3.908-1.092,6.029   c0,9.66,7.859,17.52,17.52,17.52c9.661,0,17.52-7.859,17.52-17.52c0-2.484-0.527-4.846-1.464-6.989l37.688-37.688l5.495,5.495   c1.334,1.334,3.123,2.05,4.95,2.05c0.757,0,1.521-0.123,2.262-0.376c2.526-0.862,4.342-3.087,4.681-5.735l2.953-23.081   c0.275-2.15-0.461-4.306-1.993-5.839c-1.532-1.531-3.682-2.269-5.839-1.993L226.6,139.76c-2.648,0.339-4.873,2.154-5.735,4.682   c-0.863,2.526-0.214,5.323,1.675,7.212l4.732,4.732l-38.059,38.06c-1.814-0.638-3.756-1.002-5.785-1.002   c-2.396,0-4.679,0.485-6.76,1.359l-18.132-18.134c0.642-1.82,1.009-3.768,1.009-5.805c0-9.66-7.859-17.52-17.52-17.52   c-9.661,0-17.521,7.859-17.521,17.52c0,1.962,0.338,3.843,0.936,5.605l-37.465,38.299c-1.935-0.737-4.023-1.159-6.214-1.159   c-9.661,0-17.521,7.859-17.521,17.52C64.241,240.79,72.101,248.649,81.762,248.649z M190.948,210.964c0,4.146-3.374,7.52-7.52,7.52   c-4.146,0-7.52-3.373-7.52-7.52s3.373-7.52,7.52-7.52C187.574,203.443,190.948,206.817,190.948,210.964z M142.025,163.345   c4.146,0,7.52,3.373,7.52,7.52c0,4.146-3.373,7.52-7.52,7.52s-7.521-3.374-7.521-7.52   C134.505,166.718,137.879,163.345,142.025,163.345z M81.762,223.609c4.146,0,7.52,3.374,7.52,7.52s-3.373,7.52-7.52,7.52   c-4.147,0-7.521-3.373-7.521-7.52S77.615,223.609,81.762,223.609z" />
                                                        <path
                                                            d="M308.092,8.463H17.5c-9.649,0-17.5,7.851-17.5,17.5v273.666c0,9.649,7.851,17.5,17.5,17.5h290.592   c9.649,0,17.5-7.851,17.5-17.5V25.963C325.592,16.313,317.741,8.463,308.092,8.463z M273.466,35.296c7.994,0,14.5,6.505,14.5,14.5   s-6.506,14.5-14.5,14.5c-7.996,0-14.5-6.505-14.5-14.5S265.47,35.296,273.466,35.296z M224.294,35.296   c7.995,0,14.5,6.505,14.5,14.5s-6.505,14.5-14.5,14.5c-7.995,0-14.5-6.505-14.5-14.5S216.299,35.296,224.294,35.296z    M44.399,42.296h58.5c4.143,0,7.5,3.357,7.5,7.5s-3.357,7.5-7.5,7.5h-58.5c-4.143,0-7.5-3.357-7.5-7.5S40.257,42.296,44.399,42.296   z M297.796,286.806c0,1.258-1.024,2.283-2.283,2.283H30.079c-1.259,0-2.283-1.025-2.283-2.283V98.336h270V286.806z" />
                                                    </g>
                                                </svg>&nbsp; {{ totalView }} مشاهدة
                                            </li>


                                            <!-- </template> -->

                                            <li>
                                                <!-- <div class='container d-flex justify-content-center mt-100'> <button
                                                        type="button" class="btn btn-primary mx-auto" data-toggle="modal"
                                                        data-target="#exampleModal"> Share on social media </button> </div> -->
                                                <svg data-toggle="modal" data-target="#exampleModal" fill="#0000006b"
                                                    width="23px" height="23px" viewBox="0 0 24 24"
                                                    xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M11 6.914V2.586L6.293 7.293l-3.774 3.774 3.841 3.201L11 18.135V13.9c8.146-.614 11 4.1 11 4.1 0-2.937-.242-5.985-2.551-8.293C16.765 7.022 12.878 6.832 11 6.914z" />
                                                </svg>&nbsp;مشاركة
                                            </li>
                                            <li><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                    <path
                                                        d="M22,9.67A1,1,0,0,0,21.14,9l-5.69-.83L12.9,3a1,1,0,0,0-1.8,0L8.55,8.16,2.86,9a1,1,0,0,0-.81.68,1,1,0,0,0,.25,1l4.13,4-1,5.68A1,1,0,0,0,6.9,21.44L12,18.77l5.1,2.67a.93.93,0,0,0,.46.12,1,1,0,0,0,.59-.19,1,1,0,0,0,.4-1l-1-5.68,4.13-4A1,1,0,0,0,22,9.67Zm-6.15,4a1,1,0,0,0-.29.88l.72,4.2-3.76-2a1.06,1.06,0,0,0-.94,0l-3.76,2,.72-4.2a1,1,0,0,0-.29-.88l-3-3,4.21-.61a1,1,0,0,0,.76-.55L12,5.7l1.88,3.82a1,1,0,0,0,.76.55l4.21.61Z">
                                                    </path>
                                                </svg> <span style="margin-left: 10px;margin-right: 10px;"> {{ rated
                                                    }}</span>

                                                <div>
                                                    <span v-for="star in 5" :key="star" @click.prevent="setRating(star)"
                                                        :class="{ active: star <= currentRating }"
                                                        style="font-size: 25px;cursor: pointer;">
                                                        ★
                                                    </span>
                                                </div>
                                            </li>
                                        </ul>

                                        <p>{{ publish.desc_ar }}</p>
                                    </div>
                                </div>
                                <div class="modal fade" dir="ltr" style="display: none ;" id="exampleModal"
                                    tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content col-12">
                                            <div class="modal-header">

                                                <h5 class="modal-title">مشاركة</h5>
                                                <button type="button" class="close" data-dismiss="modal"
                                                    aria-label="Close">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div class="icon-container1 d-flex">
                                                    <div class="smd"><a href="https://twitter.com/intent/tweet?text=visit Nashoof: https://nashoof.sd.zain.com "> <i class=" img-thumbnail bi bi-twitter fa-2x"
                                                            style="color:#4c6ef5;background-color: aliceblue"></i></a>
                                                        <p>Twitter</p>
                                                    </div>
                                                    <div class="smd"><a href="#" onclick="
  window.open(
    'https://www.facebook.com/sharer/sharer.php?u='+encodeURIComponent('https://nashoof.sd.zain.com/'), 
    'facebook-share-dialog', 
    'width=626,height=436'); 
  return false;"> <i class="img-thumbnail bi bi-facebook fa-2x" style="color: #3b5998;background-color: #eceff5;"></i>
                                                        </a>
                                                        <p>Facebook</p>
                                                    </div>
                                                    <div class="smd"><a href='https://api.whatsapp.com/send?text=Nashoof: https://nashoof.sd.zain.com/' target="_blank"><i class="img-thumbnail bi bi-whatsapp fa-2x"
                                                            style="color: #25D366;background-color: #cef5dc;"></i></a>
                                                        <p>Whatsapp</p>
                                                    </div>

                                                </div>

                                            </div>
                                            <div class="modal-footer"> <label style="font-weight: 600">رابط الصفحة
                                                    <span class="message"></span></label><br />
                                                <div class="row"> <input class="col-10 ur" type="url"
                                                        v-bind:placeholder="'https://nashoof.sd.zain.com/Content/' + publish.id"
                                                        readonly id="myInput"
                                                        aria-describedby="inputGroup-sizing-default"
                                                        style="height: 40px;"> <button class="cpy"
                                                        onclick="myFunction()"><i class="bi bi-copy"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row" style=" padding: 0px 5px; margin-bottom: 8px;">

                            <form @submit.prevent="addComment"
                                class="d-flex flex-row add-comment-section col-12 mtop_mbottom comment_control">
                                <img class="img-fluid img-responsive rounded-circle mr-2"
                                    src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                                    style="height:100% !important ;" width="38"><input v-model="newComment" type="text"
                                    class="form-control mr-3" style="margin-left: 1rem !important;"
                                    placeholder="إضافة تعليق">
                                <button class="btn btn-primary" type="submit" :disabled="isLoading">
                                    <i class="bi bi-chat-text"></i> ارسال
                                </button>
                            </form>
                            <!-- comments control -->
                            <div class="col-12 comment_control">
                                <a href="#" id="hide" onclick="return false">إخفاء التعليقات <svg fill="#0000006b"
                                        height="19px" width="19px" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 330 330" xml:space="preserve">
                                        <path id="XMLID_224_"
                                            d="M325.606,229.393l-150.004-150C172.79,76.58,168.974,75,164.996,75c-3.979,0-7.794,1.581-10.607,4.394  l-149.996,150c-5.858,5.858-5.858,15.355,0,21.213c5.857,5.857,15.355,5.858,21.213,0l139.39-139.393l139.397,139.393  C307.322,253.536,311.161,255,315,255c3.839,0,7.678-1.464,10.607-4.394C331.464,244.748,331.464,235.251,325.606,229.393z" />
                                    </svg></a>
                                <a href="#" id="show" onclick="return false">عرض التعليقات <svg fill="#0000006b"
                                        height="19px" width="19px" version="1.1" id="Layer_1"
                                        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 330 330" xml:space="preserve">
                                        <path id="XMLID_225_"
                                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z" />
                                    </svg></a>
                            </div>

                            <ul class="comments" id="comments">

                                <!-- FIRST COMMENT -->
                                <li class="comment" v-for="comment in getComments" :key="comment.id">
                                    <div class="comment-content">
                                        <div class="avatar">
                                        </div>
                                        <span class="date">
                                            <!-- <i class="far fa-clock"></i> -->
                                            &nbsp;
                                            <i class="far fa-calendar-alt"></i>
                                            {{ comment.comment_date }}</span>
                                        <span class="username" v-if="comment.full_name != ''">{{ comment.full_name }}</span>
                                        <span class="username" v-else>مستخدم نشوف</span>

                                        <p>{{ comment.comment_text }}</p>

                                    </div>





                                </li>
                                <!-- END : FIRST COMMENT -->



                            </ul>
                        </div>
                    </div>
                    <div class="col-12 col-xl-3" style="margin-top: 40px;">
                        <div class="sidebar sidebar--mt" style="margin-top: 2px;">
                            <div class="row row--grid">
                                <div class="col-12" v-for="publish in getCatByID" :key="publish.id">
                                    <div class="row"
                                        style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="padding-right: 0px;">
                                            <div class="card"
                                                style="background-image: linear-gradient(0deg, rgba(0, 66, 105, 0) 0px, rgba(113, 198, 247, 0)); border-radius: 0px; box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 15px 20px; margin-top: 0px;height: auto;width: 145px;display: flex;position: relative;">
                                                <a @click="getFeed(publish)" class="card__cover" style="height: auto;">
                                                    <img :src="publish.img" alt="">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="text-align: right;">
                                            <a @click="getFeed(publish)">
                                                <ul class="">
                                                    <li>
                                                        <h5 class="card__title"
                                                            style="color: rgb(4, 97, 98); margin-top: 13px;">{{
                                                                publish.title_ar }}</h5>
                                                    </li>
                                                    <li>
                                                        <h6 style="color: #046162;">{{ publish.desc_ar }}</h6>
                                                    </li>
                                                    <li>
                                                        <h6 style="color: #046162;">{{ publish.name_ar }}</h6>
                                                    </li>
                                                    <li style="color: #046162;">{{ publish.year }}</li>
                                                </ul>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12" v-for="publish in getCatByID" :key="publish.id">
                                    <div class="row"
                                        style="background: rgba(131, 137, 159, 0.07) none repeat scroll 0% 0%; padding: 0px 5px; margin-bottom: 8px;">
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="padding-right: 0px;">
                                            <div class="card"
                                                style="background-image: linear-gradient(0deg, rgba(0, 66, 105, 0) 0px, rgba(113, 198, 247, 0)); border-radius: 0px; box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px, rgba(0, 0, 0, 0) 0px 15px 20px; margin-top: 0px;height: auto;width: 145px;display: flex;position: relative;">
                                                <a @click="getFeed(publish)" class="card__cover" style="height: auto;">
                                                    <img :src="publish.img" alt="">
                                                    <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z"
                                                            stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                </a>
                                            </div>

                                        </div>
                                        <div class="col-6 col-sm-4 col-md-3 col-xl-6" style="text-align: right;">
                                            <a @click="getFeed(publish)">
                                                <ul class="">
                                                    <li>
                                                        <h5 class="card__title"
                                                            style="color: rgb(4, 97, 98); margin-top: 13px;">{{
                                                                publish.title_ar }}</h5>
                                                    </li>
                                                    <li>
                                                        <h6 style="color: #046162;">{{ publish.desc_ar }}</h6>
                                                    </li>
                                                    <li>
                                                        <h6 style="color: #046162;">{{ publish.name_ar }}</h6>
                                                    </li>
                                                    <li style="color: #046162;">{{ publish.year }}</li>
                                                </ul>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- end article -->
        </div>


    </section>
    <!-- end details -->
    <!-- <div class="catalog2 catalog--page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <ul class="title"><li style="margin-left: 30px;margin-right:15px"><h6 >الاكثر مشاهدة </h6></li></ul>
                    </div>
                    <div class="col-12">
                        <div class="row row--grid">
                            <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="publish in getCatByID" :key="publish.id">
                                <div class="card">
                                    <a @click="getFeed(publish)" class="card__cover">
                                        <img :src="publish.img" alt="">
                                        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M11 1C16.5228 1 21 5.47716 21 11C21 16.5228 16.5228 21 11 21C5.47716 21 1 16.5228 1 11C1 5.47716 5.47716 1 11 1Z" stroke-linecap="round" stroke-linejoin="round"/><path fill-rule="evenodd" clip-rule="evenodd" d="M14.0501 11.4669C13.3211 12.2529 11.3371 13.5829 10.3221 14.0099C10.1601 14.0779 9.74711 14.2219 9.65811 14.2239C9.46911 14.2299 9.28711 14.1239 9.19911 13.9539C9.16511 13.8879 9.06511 13.4569 9.03311 13.2649C8.93811 12.6809 8.88911 11.7739 8.89011 10.8619C8.88911 9.90489 8.94211 8.95489 9.04811 8.37689C9.07611 8.22089 9.15811 7.86189 9.18211 7.80389C9.22711 7.69589 9.30911 7.61089 9.40811 7.55789C9.48411 7.51689 9.57111 7.49489 9.65811 7.49789C9.74711 7.49989 10.1091 7.62689 10.2331 7.67589C11.2111 8.05589 13.2801 9.43389 14.0401 10.2439C14.1081 10.3169 14.2951 10.5129 14.3261 10.5529C14.3971 10.6429 14.4321 10.7519 14.4321 10.8619C14.4321 10.9639 14.4011 11.0679 14.3371 11.1549C14.3041 11.1999 14.1131 11.3999 14.0501 11.4669Z" stroke-linecap="round" stroke-linejoin="round"/></svg>
                                    </a>
                                    <h3 class="card__title"><a @click="getFeed(publish)">{{ publish.title_ar }}</a></h3>
                                    <ul class="card__list">
                                        
                                        <li>{{ publish.name_ar }}</li>
                                        <li>{{ publish.year }}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>		
            </div>

        </div> -->
</template>

<script>
// import { BFormRating } from 'bootstrap-vue';
import axios from "axios";
import { ref } from 'vue';
import config from '@/Api/config';

import { useCookie } from 'vue-cookie-next'
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router"


export default {
    components: {
        // BFormRating
    },

    props: {
        rating: {
            type: Number,
            default: 0,
        }
    },
    name: 'Contents',
    data() {
        return {
            isRated: false,
            currentRating: this.rating,
            rated: 0,
        };
    },

    async setup() {

        const router = useRouter();
        const cookie = useCookie();
        const toast = useToast();
        const content = ref([]);
        const getCatByID = ref([]);
        const newComment = ref('');
        const getComments = ref([]);
        const getCommentslength = ref(0);
        const isLoading = ref(false);
        const totalLikes = ref(0);
        const totalView = ref(0);
        const isLiked = ref(false); // Track whether the icon is liked


        const fetchContentById = async () => {

            try {
                const response = await axios.get(`ContentById.php?CID=${cookie.getCookie("Content_id")}`, {
                    headers: { 'Authorization': config.authHeader, },
                });

                if (response.data && response.data.Content) {
                    content.value = response.data.Content || [];
                } else {
                    console.warn('No content found in the response');
                }

            } catch (error) {
                console.error("Error fetching Content:", error);
            }

        };

        const fetchCategoryById = async () => {

            try {
                const response = await axios.get(`GetContentByCategory.php?cat_id=${cookie.getCookie("cat_id")}&LIMIT=12&OFFSET=0`, {
                    headers: { 'Authorization': config.authHeader, },
                });

                if (response.data && response.data.Content) {
                    getCatByID.value = response.data.Content || [];
                } else {
                    console.warn('No content found in the response');
                }

            } catch (error) {
                console.error("Error fetching Content:", error);
            }
        };

        // Fetch total likes from the API
        const fetchTotalLikes = async () => {

            try {
                const response = await axios.get(`GetInteractionbyContentId.php?content_id=${cookie.getCookie("Content_id")}`, {
                    headers: { 'Authorization': config.authHeader, },
                });

                // Extract the interaction_count for "like"
                const interactionData = response.data.Interaction || [];

                const likeInteraction = interactionData.find(
                    (interaction) => interaction.interaction_type === "like"
                );

                const viewInteraction = interactionData.find(
                    (interaction) => interaction.interaction_type === "view"
                );

                totalLikes.value = likeInteraction ? likeInteraction.interaction_count : 0;
                totalView.value = viewInteraction ? viewInteraction.interaction_count : 0;
                isLiked.value = false; // Set this based on whether the user has liked (API dependent)

                // console.log(totalLikes)

            } catch (error) {
                console.error("Error fetching total likes:", error);
            }
        };


        // Add a like via the API
        const addLike = async () => {

            try {

                if (isLiked.value) {
                    // If already liked, call an API to unlike (if available)
                    await axios.get(`AddUserInteraction.php?username=${cookie.getCookie("mdn")}&content_id=${cookie.getCookie("Content_id")}&interaction_type=dislike`, {
                        headers: { 'Authorization': config.authHeader, },
                    });

                    await fetchTotalLikes(); // Update the total likes after adding a like
                    isLiked.value = !isLiked.value;
                    // isLiked.value = false;
                    totalLikes.value--; // Update UI optimistically

                } else {

                    await axios.get(`AddUserInteraction.php?username=${cookie.getCookie("mdn")}&content_id=${cookie.getCookie("Content_id")}&interaction_type=like`, {
                        headers: { 'Authorization': config.authHeader, },
                    });

                    await fetchTotalLikes(); // Update the total likes after adding a like
                    isLiked.value = !isLiked.value;

                }
            } catch (error) {
                console.error("Error adding like:", error);
            }
        };


        // Fetch Comments from API
        const fetchComments = async () => {

            try {

                const response = await axios.get(`getCommentsByContentId.php?content_id=${cookie.getCookie("Content_id")}`, {
                    headers: { 'Authorization': config.authHeader, },
                });

                const data = await response.data.Comments;
                // Sort comments by oldest first (assuming `created_at` exists)
                getComments.value = data.sort((a, b) => new Date(a.comment_date) - new Date(b.comment_date));

                // getComments.value = response.data.Comments || [];
                getCommentslength.value = response.data.Comments.length

            } catch (err) {
                console.log(err);
            }
        };


        const addComment = async () => {

            //   if (!newComment.value.trim()) return;
            if (!newComment.value.trim()) {
                toast.info("لايمكن اضافة تعليق فارغ", {
                    timeout: 4000
                });

                return;
            }

            isLoading.value = true

            try {
                // const contentId = props.content_id; // Replace with dynamic content ID if needed
                const commentText = (newComment.value.trim());

                await axios.get(`AddComment.php?username=${cookie.getCookie("mdn")}&content_id=${cookie.getCookie("Content_id")}&comment_text=${commentText}`, {
                    headers: { 'Authorization': config.authHeader, },
                }).then((response) => {
                    if (response.data.error_code == 0) {
                        toast.success("تم التعليق", {
                            timeout: 4000
                        });
                        newComment.value = ''; // Clear the input field

                        fetchComments();
                    } else {
                        toast.error("لم يتم التعليق", {
                            timeout: 4000
                        });
                    }

                }).catch((error) => {
                    console.log(error);
                }).finally(() => {
                    isLoading.value = false
                });
            } catch (error) {
                console.log(error);
            }
        };

        // onMounted(() => {
        await fetchTotalLikes();
        await fetchComments(); // No "await" here
        await fetchContentById();
        await fetchCategoryById();
        // });


        const getFeed = (publish) => {
            

            // try {
                if(cookie.isCookieAvailable("mdn")){

                 axios.get(`AddUserInteraction.php?username=${cookie.getCookie("mdn")}&content_id=${publish.id}&interaction_type=view`, {
                    headers: { 'Authorization': config.authHeader, 
                },
                }).then((response) => {
                    if (response.data.error_code == 0) {
                        console.log("Add Interaction Success");
                        cookie.setCookie('Content_id', publish.id);
            router.push({ name: "Content" });
            router.go()
                    } else {
                        console.log("Add Interaction failed");
                        cookie.setCookie('Content_id', publish.id);
            router.push({ name: "Content" });
            router.go()
                    }
                });
            }

            //     if (response.data.error_code === 0) {
            //         console.log("Add Interaction Success");
            //     } else {
            //         console.log("Add Interaction failed");
            //     }

            // } catch (error) {
            //     console.error("Error Interaction view:", error);
            // }

            // cookie.setCookie('Content_id', publish.id);
            // router.push({ name: "Content" });
            // router.go()
            //  router.push({ name: "Contents", params: { content_id: publish.id } });
        };

        return {
            content,
            getCatByID,
            newComment,
            getComments,
            isLoading,
            addComment,
            totalLikes,
            totalView,
            addLike,
            getFeed,
        };





    },
    watch: {
        $route() {
            window.scrollTo(0, 0);
            // this.$router.go();
        },
    },
    mounted() {
        // const cookie = useCookie()






        axios.get(
            "https://nashoof.sd.zain.com/DSPAPIs/APIs/DSPCheckLogin.php?msisdn=" + this.$cookie.getCookie("mdn") 
            , {
                headers: {
                    'Authorization': config.authHeader,
                },
            }).then((response) => {
                if (response.data.status == 1 && response.data.remming_minutes > 0) {
                    return
                } else {
                    this.$router.push("/");
                }
            });

        // axios.get(
        //     "UpdateViews.php?msisdn=" + this.$cookie.getCookie("mdn") + "&content_id=" + this.$cookie.getCookie("Content_id")
        //     , {
        //         headers: {
        //             'Authorization': config.authHeader,
        //         },
        //     }).then((response) => {
        //         return response.data;
        //     });

    },
    methods: {

        setRating(star) {
            const toast = useToast();

            this.currentRating = star;
            this.$emit('update:rating', star);
            // console.log("Rating ============================================ " + this.currentRating);


            if (!(this.isRated)) {
                // https://nashoof.sd.zain.com/NashoofAP_I/APIs/AddRating.php?username=249912390141&content_id=185&rating=5
                axios.get('AddRating.php?username=' + this.$cookie.getCookie("mdn") + "&content_id=" + this.$cookie.getCookie("Content_id") + "&rating=" + this.currentRating, {
                    headers: {
                        'Authorization': config.authHeader,
                    },
                })
                    .then((response) => { return response.data.Content; });
                this.isRated = true;
                toast.success("شكرا لتقييمك", {
                    timeout: 4000
                });
                this.rated = this.currentRating;
            } else {
                toast.info("لقد قمت بالتقييم من قبل", {
                    timeout: 4000
                });
            }

        },


    },
}
</script>

<style>
.pointer {
    cursor: pointer;
}

.title {
    font-family: 'Cairo', sans-serif;
    font-weight: 500;
    font-size: 18px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 5px;
    transition: 0.5s;
    display: block;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    text-align: start;
    overflow: hidden;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    word-wrap: break-word;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
    padding: 8px 5px 5px 5px;
    border-radius: 12px;


}

.catalog2 {
    position: relative;
    padding: 5px 0 25px;
    padding-top: calc(50vh - 90%);
    padding-right: 0px;
    padding-bottom: 25px;
    padding-left: 0px;
    overflow: hidden;
    padding-bottom: calc(50vh - 60%);
}

.catalog__paginator-wrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 40px auto 0;
    width: 100%;
    height: 50px;
    border-radius: 16px;
    background-color: #151f30;
    background-color: #047072;
    background-image: linear-gradient(62deg, #047072 0%, #00c9c9 100%);
}



.active {
    color: gold;
}
</style>